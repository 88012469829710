export const CARD_SIZES = {
  POSTER: {
    width: 273,
    height: 460,
    image_width: 273,
    image_height: 375,
  },
  LANDSCAPE: {
    width: 315,
    height: 410,
    image_width: 315,
    image_height: 205,
  },
  THUMBNAIL: {
    width: 0,
    height: 0,
  },
  SPORTS: {
    // width: 260,
    width: 350,
    height: 335,
  },
  CATEGORY: {
    height: 64,
    width: 1740,
  },
  EPG_CARD_ITEM: {
    height: 105,
  },
  HERO: {
    width: 385,
    height: 215,
    image_width: 385,
    image_height: 215,
  },
  COLLECTION: {
    width: 415,
    height: 285,
    image_width: 415,
    image_height: 230,
  },
  BANNER: {
    width: 1760,
    // height: 460,
    height: 300,
    image_width: 1760,
    image_height: 150,
  },
};
